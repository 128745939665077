var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: {
      app_item_master_display: true,
      is_initialized: _vm.shouldShowItemMasterDisplay
    }
  }, [!_vm.shouldShowItemMasterDisplay ? _c('AppLoader', {
    staticClass: "loader",
    attrs: {
      "color": _vm.EntityType.ITEM_DRAWING
    }
  }) : _vm._e(), !_vm.isMinimized ? _c('div', {
    class: {
      minimized: _vm.isMinimized,
      is_initialized: _vm.shouldShowItemMasterDisplay
    }
  }, [_c('div', {
    staticClass: "ahd--grid-container item_master_display_grid"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Drawing num:")]), _vm.transformedItemDrawing ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedItemDrawing.drawingNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("AsBuilt:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._asBuiltNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Side:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._side))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Item num:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._itemNumber))])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item all_drawing_sizes"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Sizes:")]), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.sizes && _vm.transformedSelectedEntity.sizes.length ? _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.transformedSelectedEntity._sizes, function (size) {
    return _c('li', {
      key: size,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(size) + " ")]);
  }), 0) : _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v("—")])])]), _c('div', {
    staticClass: "ahd--column-two"
  }, [_vm.transformedItemDrawing && _vm.transformedItemDrawing.meanTimeBetweenFailure ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from drawing ${_vm.transformedItemDrawing._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTBF:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemDrawing._meanTimeBetweenFailure) + " ")])]) : _vm._e(), _vm.transformedItemDrawing && _vm.transformedItemDrawing.meanTimeToRepair ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `Inherited from drawing ${_vm.transformedItemDrawing._itemNumber}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTTR:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedItemDrawing._meanTimeToRepair) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("PoC:")]), _vm.transformedItemDrawing ? _c('p', {
    staticClass: "pl-0 font-weight-bold",
    staticStyle: {
      "width": "max-content"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedItemDrawing._pointOfContact) + " ")]) : _vm._e()]), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Prop cls:")]), _vm.transformedItemDrawing ? _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._propertyClass) + " ")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "ahd--column-three"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("CEI num:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._ceiNumber) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("CEI lvl:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._ceiLevel) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Crit code:")]), _vm.transformedSelectedEntity ? _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._criticalityCodeShort) + " ")]) : _vm._e()]), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `${_vm.transformedSelectedEntity._subType}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Equip grp:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._subTypeNumberOnly) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--grid-item",
    attrs: {
      "tooltip": `${_vm.transformedSelectedEntity._federalSupplyClassNumber} - ${_vm.transformedSelectedEntity._federalSupplyClass}`
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("FSC:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._federalSupplyClassNumber) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity._objectClass !== _vm.DEFAULT_DASH ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Obj cls:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._objectClass) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.hasSoftwareVersion ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Soft ver:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._softwareVersion) + " ")])]) : _vm._e()]), _vm.transformedSelectedEntity && _vm.myLimitedLife && _vm.myLimitedLife.hasAnyLimitedLifeQuotas ? _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm.myLimitedLife.hasLimitedLifeCodes ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("LL code:")]), _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.myLimitedLife.orderedLimitedLifeCodes, function (code) {
    return _c('li', {
      key: code,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(code) + " ")]);
  }), 0)]) : _vm._e(), _vm.myLimitedLife.hasAnyCycleBasedLifeQuotas ? _c('hr', {
    staticClass: "mr-3"
  }) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeCycle ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Cycle:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.cycle.expirationQuotaUses) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.cycle.serviceQuotaUses) + " ")])])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeEva ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("EVA:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.eva.expirationQuotaUses) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.eva.serviceQuotaUses) + " ")])])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeFlight ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Flight:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.flight.expirationQuotaUses) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.flight.serviceQuotaUses) + " ")])])]) : _vm._e(), _vm.myLimitedLife.hasAnyDateBasedLifeQuotas ? _c('hr', {
    staticClass: "mr-3"
  }) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeOperation ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Operational:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.operation.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.operation.serviceQuotaInterval)) + " ")]), _vm.transformedSelectedEntity.limitedLife.operation.overrideExpirationDate ? _c('br') : _vm._e(), _vm.transformedSelectedEntity.limitedLife.operation.overrideExpirationDate ? _c('span', {
    attrs: {
      "tooltip": "Override expiration date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.transformedSelectedEntity.limitedLife.operation.overrideExpirationDate)) + " ")]) : _vm._e()])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeShelf ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Shelf:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.shelf.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.shelf.serviceQuotaInterval)) + " ")]), _vm.transformedSelectedEntity.limitedLife.shelf.overrideExpirationDate ? _c('br') : _vm._e(), _vm.transformedSelectedEntity.limitedLife.shelf.overrideExpirationDate ? _c('span', {
    attrs: {
      "tooltip": "Override expiration date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.transformedSelectedEntity.limitedLife.shelf.overrideExpirationDate)) + " ")]) : _vm._e()])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeUsage ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Usage:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.usage.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.usage.serviceQuotaInterval)) + " ")]), _vm.transformedSelectedEntity.limitedLife.usage.overrideExpirationDate ? _c('br') : _vm._e(), _vm.transformedSelectedEntity.limitedLife.usage.overrideExpirationDate ? _c('span', {
    attrs: {
      "tooltip": "Override expiration date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.transformedSelectedEntity.limitedLife.usage.overrideExpirationDate)) + " ")]) : _vm._e()])]) : _vm._e(), _vm.myLimitedLife.hasAnyIntervalBasedLifeQuotas ? _c('hr', {
    staticClass: "mr-3"
  }) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeInterval ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Interval:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.interval.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.interval.serviceQuotaInterval)) + " ")])])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeOnOrbit ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("On orbit:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.onOrbit.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.onOrbit.serviceQuotaInterval)) + " ")])])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifePressurizedTime ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("P time:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.pressurizedTime.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.pressurizedTime.serviceQuotaInterval)) + " ")])])]) : _vm._e(), _vm.myLimitedLife.hasLimitedLifeStatic ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Static:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_c('span', {
    attrs: {
      "tooltip": "Expiration quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.static.expirationQuotaInterval)) + " ")]), _vm._v(" / "), _c('span', {
    attrs: {
      "tooltip": "Service quota"
    }
  }, [_vm._v(" " + _vm._s(_vm.$intervalDisplay(_vm.transformedSelectedEntity.limitedLife.static.serviceQuotaInterval)) + " ")])])]) : _vm._e()]) : _vm._e(), _vm.transformedSelectedEntity && !_vm.selectedEntityHasLimitedLifeQuotaDefined ? _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm._m(0)]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--column-five"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Approved AsDesign:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px",
      "tooltip": _vm.transformedSelectedEntity.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign'
    },
    model: {
      value: _vm.transformedSelectedEntity.isApprovedAsDesign,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isApprovedAsDesign", $$v);
      },
      expression: "transformedSelectedEntity.isApprovedAsDesign"
    }
  })], 1)]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Approved Data Pack:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isApprovedDataPack,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isApprovedDataPack", $$v);
      },
      expression: "transformedSelectedEntity.isApprovedDataPack"
    }
  })], 1)]), _vm.transformedSelectedEntity.isCleanlinessTracking ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Cleanliness tracked:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isCleanlinessTracking,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isCleanlinessTracking", $$v);
      },
      expression: "transformedSelectedEntity.isCleanlinessTracking"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.limitedLife && _vm.transformedSelectedEntity.limitedLife.disableAlerts ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Disabled LL alerts:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.limitedLife.disableAlerts,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity.limitedLife, "disableAlerts", $$v);
      },
      expression: "transformedSelectedEntity.limitedLife.disableAlerts"
    }
  })], 1)]) : _vm._e(), _vm.transformedItemDrawing ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("EEE identifier:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedItemDrawing.eeeIdentifier,
      "icon-size": "16px"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.isFractureCritical ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Fracture crit:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isFractureCritical,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isFractureCritical", $$v);
      },
      expression: "transformedSelectedEntity.isFractureCritical"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.isHazardousMaterial ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Hazardous mat:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isHazardousMaterial,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isHazardousMaterial", $$v);
      },
      expression: "transformedSelectedEntity.isHazardousMaterial"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Initialization:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "16px",
      "icon": _vm.transformedSelectedEntity._initializationStatusIconOnly,
      "icon-color": _vm.transformedSelectedEntity._initializationStatusIconColor,
      "tooltip": _vm.transformedSelectedEntity._initializationStatusIconTitle
    }
  })], 1)]), _vm.transformedSelectedEntity.isManifestable ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Manifestable:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isManifestable,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isManifestable", $$v);
      },
      expression: "transformedSelectedEntity.isManifestable"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.isRefurbishable ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Refurbishable:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isRefurbishable,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isRefurbishable", $$v);
      },
      expression: "transformedSelectedEntity.isRefurbishable"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.isSpecialHandling ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special handling:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isSpecialHandling,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isSpecialHandling", $$v);
      },
      expression: "transformedSelectedEntity.isSpecialHandling"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.isSpecialPackaging ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special packaging:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px",
      "tooltip": _vm.transformedSelectedEntity.isSpecialPackaging ? 'Yes, special packaging is required' : 'No special packaging required'
    },
    model: {
      value: _vm.transformedSelectedEntity.isSpecialPackaging,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isSpecialPackaging", $$v);
      },
      expression: "transformedSelectedEntity.isSpecialPackaging"
    }
  })], 1)]) : _vm._e(), _vm.transformedSelectedEntity.isTool ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Tool:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "icon-size": "16px"
    },
    model: {
      value: _vm.transformedSelectedEntity.isTool,
      callback: function ($$v) {
        _vm.$set(_vm.transformedSelectedEntity, "isTool", $$v);
      },
      expression: "transformedSelectedEntity.isTool"
    }
  })], 1)]) : _vm._e()]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv control:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._inventoryControl) + " ")])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv method:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._inventoryMethod) + " ")])]), _vm.transformedSelectedEntity._showInventoryUnitType ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inv unit:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._inventoryUnitTypeShort) + " ")])]) : _vm._e(), _c('hr', {
    staticClass: "ml-3"
  }), _vm._m(1), _vm._m(2), _vm._m(3)]) : _vm._e()]), _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Description:")]), _vm.transformedSelectedEntity ? _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))]) : _vm._e()]), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.opsNom ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Ops nom:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._opsNom) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.myLimitedLife && _vm.myLimitedLife.hasLimitedLifeDescription ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item",
    attrs: {
      "tooltip": "This is taken from the 'Description' field in the Limited Life section"
    }
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Limited life:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.limitedLife.description) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.hazardousMaterialText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Hazardous material:")]), _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._hazardousMaterialText))])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.specialHandlingText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special handling:")]), _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._specialHandlingText))])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.cleanlinessTrackingText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Cleanliness tracking:")]), _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._cleanlinessTrackingText))])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.specialPackagingText ? _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Special packaging:")]), _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._specialPackagingText))])]) : _vm._e(), _vm.isArchived ? _c('div', {
    staticClass: "ahd--solo-row mt-2"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1) : _vm._e()]) : _vm._e(), !_vm.isFetching && _vm.isMinimized && _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "is_initialized"
  }, [_c('MiniItemMaster', {
    attrs: {
      "drawingNumber": _vm.transformedSelectedEntity.drawingNumber,
      "asBuiltNumber": _vm.transformedSelectedEntity.asBuiltNumber,
      "side": _vm.transformedSelectedEntity.side,
      "show-manage-link": false
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "ahd--solo-row mt-2",
    class: {
      is_initialized: _vm.shouldShowItemMasterDisplay
    }
  }, [_vm.transformedItemDrawing && _vm.transformedItemDrawing._urlManage ? _c('router-link', {
    staticClass: "adh_button_link",
    class: {
      'ml-2': _vm.hasLinks
    },
    attrs: {
      "tooltip": "View Item drawing manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemDrawing._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity._urlManage ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item master manage page",
      "tooltip-position": "right",
      "to": _vm.transformedSelectedEntity._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasItemInstances && _vm.transformedItemInstances.length > 1 ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-item_instances-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-item_instances-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Item instances ")], 1), _vm._l(_vm.transformedItemInstances, function (ii, index) {
    return _c('div', {
      key: index
    }, [ii._urlManage ? _c('c-menu-item', {
      attrs: {
        "href": ii._urlManage,
        "icon": _vm.$icons.MANAGE_PAGE,
        "icon-size": "16px",
        "icon-color": "var(--v-item_instances-base)"
      }
    }, [ii.asBuiltNumber !== _vm.DB_DEFAULT ? _c('span', [_c('small', [_vm._v("ab")]), _c('span', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(ii._asBuiltNumber))]), _vm._v("  ")]) : _vm._e(), ii.side !== _vm.Side.NONE ? _c('span', [_c('small', [_vm._v("sd")]), _c('span', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(ii._side))]), _vm._v("  ")]) : _vm._e(), ii.size && ii.size !== _vm.DB_DEFAULT ? _c('span', [_c('small', [_vm._v("sz")]), _c('span', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(ii._size))]), _vm._v("  ")]) : _vm._e(), ii.lotNumber ? _c('span', [_c('small', [_vm._v("lt")]), _c('span', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(ii._lotNumber))]), _vm._v("  ")]) : _vm._e(), ii.serialNumber ? _c('span', [_c('small', [_vm._v("sn")]), _c('span', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(ii._serialNumber))])]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.hasItemInstances && _vm.transformedItemInstances.length === 1 && _vm.transformedItemInstances[0]._urlManage ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item instance manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemInstances[0]._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasLinks ? _c('c-menu', {
    attrs: {
      "align-left": "",
      "border-color": "var(--v-comments-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-comments-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.link,
      "icon-color": "#fff"
    }
  }), _vm._v(" Links ")], 1), _vm._l(_vm.transformedLinks, function (link, index) {
    return _c('div', {
      key: index
    }, [link.currentVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.currentVersionUrl,
        "icon": _vm.$icons.link,
        "icon-color": "var(--cosmic-text)"
      }
    }, [_vm._v(" " + _vm._s(link.currentVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e(), _c('v-divider'), link.workingVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.workingVersionUrl,
        "icon": _vm.$icons.link,
        "icon-color": "var(--cosmic-text)"
      }
    }, [_vm._v(" " + _vm._s(link.workingVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.transformedSelectedEntity && _vm.changelogHref ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": `View the changelog for ${_vm.transformedSelectedEntity._itemNumber}`,
      "tooltip-position": "right",
      "to": _vm.changelogHref
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.history,
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center ml-2"
  }, [!_vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-down-left-and-arrow-up-right-to-center",
      "tooltip": "Minimize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMinimize
    }
  }) : _vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-up-right-and-arrow-down-left-from-center",
      "tooltip": "Maximize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMaximize
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--no_limited_life_set"
  }, [_c('strong', {
    staticClass: "pl-2"
  }, [_c('i', [_vm._v("No LL quotas set")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Avail qty:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inst qty:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Qty:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")])])]);
}];
export { render, staticRenderFns };