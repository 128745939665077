var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.computedClasses
  }, [_c('v-card', {
    staticClass: "pa-2",
    class: {
      required_border: _vm.required
    },
    attrs: {
      "flat": ""
    }
  }, [_vm.required ? _c('v-chip', {
    staticClass: "required_label",
    attrs: {
      "label": "",
      "outline": "",
      "color": "red",
      "disabled": ""
    }
  }, [_vm._v("required")]) : _vm._e(), _c('v-form', _vm._g({
    ref: "form"
  }, {
    ..._vm.$listeners,
    submit: _vm.onSubmit
  }), [_vm._t("default")], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };